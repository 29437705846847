import Pinimage from "../Images/Pin.png";
import Pickerimage from "../Images/Picker.png";
import staticesimage from "../Images/statices.png";
import Tracker from "../Images/Tracker.png";
import pinone from "../Images/pin1.png";
import locationimage from "../Images/location.png";


const Data = {
  sacion: [
    {
      title: "SHADE THE MAP",
      paragraph:
        "Shade in the states and countries you’ve visited and customize the colors.",
      image: Pinimage,
    },
    {
      title: "BUCKET LIST",
      paragraph:
        "A place to list and keep track of your dream destinations. How many can you check off?",
      image: locationimage,
    },
    {
      title: "TRAVEL TRACKER",
      paragraph:
        "On a road trip and want to track your route?  VAGABOND’s got you covered.",
      image: staticesimage,
    },
  ],
  rightsaction: [
    {
      title: "TRACK YOUR STATS ",
      paragraph:
        "VAGABOND tracks the places you visit, so you can easily view your global footprint.",
      image: Tracker,
    },

    {
      title: "DROP PINS ON THE MAP",
      paragraph:
        `Don't forget the places you want to go. Drop pins, add pictures and save for later.`,
      image: pinone,
    },
    {
      title: "SAVE PHOTOS",
      paragraph:
        "Utilize your map further by posting pictures from your travels.",
      image: Pickerimage,
    },
  ],
};

export default Data;
